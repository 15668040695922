import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import { graphql } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links";
import * as styleContent from '../../components/content/content.module.scss'
import * as stylePanel from '../../components/panels/panel.module.scss'
import Img from "gatsby-image"


const Services = ({data}) => (
  <Layout>
    <Seo title="Services" bodyClass="stp"/>
    
    <div className="column column--30 alignright">
      <div className="sticky">
        <span className={styleContent.titleLowercase}>Services</span>
        <div className={styleContent.sideMenu}>
          <AnchorLink to="stp/services#AvenueKodeStudios" title="Avenue Kode Studios" />
          <AnchorLink to="stp/services#SpecialEventServices" title="Special  Event Services" />
          <AnchorLink to="stp/services#CustomMadeThemedSongs" title="Custom-Made Themed Songs" />
          <AnchorLink to="stp/services#TVFilmInstrumentals" title="TV &amp; Film Instrumentals" />
          <AnchorLink to="stp/services#PianoLessonsFromScratch" title="Piano Lessons From Scratch!" />
          <AnchorLink to="stp/services#Songwriting" title="Songwriting" />
          <AnchorLink to="stp/services#LivePerformances" title="Live Performances" />
          <AnchorLink to="stp/services#ArtistConsultant" title="Artist Consultant" />
        </div>
      </div>
    </div>
    <div className="column column--70">

      <div className={stylePanel.panel} id="AvenueKodeStudios">
        <div className={stylePanel.panel__header}>
          Avenue Kode Studios
        </div>
        <div className={stylePanel.panel__content}>
          <Img className={styleContent.imageBodyHalf + ' float-right'}  fluid={data.avenuekode.childImageSharp.fluid}/>
          <p>Female run and operated, works with various artists from established to up and coming, helping them put together and release albums, audition prep, to facilitating their live shows and overall musical direction.</p>
          <p>She can assist you with all aspects of your career to help maximize the longevity of your artist brand, at affordable rates that work within your budget. Let us take care of the back end while you focus on your craft.</p>
          <p>Educated and certified industry pro with over 25 years in the business as a musician (soloist, accompanist, studio, in bands: stage & touring etc), vocalist, songwriter composer and executive producer.</p>
          <p>Credits include: Universal Music, YTV, Much Music</p>
          <p>She’s the industry pro in the K.N.O.W! [Knowledge, Notability, Originality, Wow factor!]</p>
          <p>Let’s discuss your next project!</p>
        </div>

        <hr/>
        <span className={styleContent.subtitleLowercase}>Rates</span>
        
        <div className="grid grid--third">

          <div>
            <p><strong>Lessons Area</strong><br/> 
            Rate: $40/hr</p>
            <p>Musicianship Training<br/>
            Piano &amp; Theory Lessons - Modern &amp; Classical Syles (Top 40, RCM)</p>
          </div>
          <div>
            <p><strong>Studio Area</strong> <br/>
            Rate: $25/hr <small>(min 2 hours)</small></p>
            <p>Recordings for: Single, Demo &amp; Album Projects<br/>
            Audition Tapes<br/>
            School Projects</p>
          </div>
          <div>
            <p><strong>Performance Area</strong><br/>
            Rate: $20/hr</p>
            <p>Audition &amp; Live Performance&nbsp;Prep,<br/>
            General Rehearsal to Advance Your Craft</p>
          </div>

      </div>
      <hr/>
        <span className={styleContent.subtitleLowercase}>Contact</span>
        <p>Specyal T<br/>
        <strong>Phone</strong> 647-273-2327<br/>
        <strong>Email</strong> <a href="mailto:avenuekode@gmail.com">avenuekode@gmail.com</a></p>
      </div>

      <div className={stylePanel.panel} id="SpecialEventServices">
        <div className={stylePanel.panel__header}>
          Special Event Services
        </div>

        <p>Your Specyal Source For Special Events!<br/>
        <span role="img" aria-label="email">📧</span> <a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com</a>
        </p>

        <hr/>
      
        <span className={styleContent.subtitleLowercase}>Specyal Services &amp; Referrals</span>
        <div className="grid grid--half">

          <div>
            <p>•Live Music <br/>
            •Transportation <br/>
            •Photography <br/>
            •Videography <br/>
            •Custom Gifts </p>
          </div>

          <div>
            <p>Wedding Ceremonies + Events <span role="img" aria-label="Wedding Ceremonies + Events">👰🏽</span> <br/>
            Baby Showers &amp; Sip n Sees <span role="img" aria-label="Baby Showers &amp; Sip n Sees ">👶🏾</span><br/>
            Holiday Parties <span role="img" aria-label=" Holiday Parties">🎉</span> <br/>
            Housewarmings <span role="img" aria-label="Housewarmings">🏡</span> <br/>
            Any Special Occasion <span role="img" aria-label="Any Special Occasion">💝</span></p>
          </div>
      
        </div>

      </div>

      <div className={stylePanel.panel} id="CustomMadeThemedSongs">
        <div className={stylePanel.panel__header}>
        Custom-Made Themed Songs
        </div>
        <div className={stylePanel.panel__content}>

        <Img className={styleContent.imageBodyHalf + ' float-right'}  fluid={data.custommadethemedsongs.childImageSharp.fluid}/>
        <p>Our signature service! Whether it is a birthday or the birth of a new baby, we can write a song for it! Any theme you can come up with. For all ages!</p>

          <p><strong>What we do...</strong><br/>
          2-3 Min. (Approx) Song<br/>
          Fun facts said about the chosen girl/guy in iong<br/>
          A personalized dedication given at end of song</p>

          
          <p><strong>What we need from you...</strong><br/>
          Name, Age, Birth date of chosen girl/guy<br/>
          15-20 facts about the chosen girl/guy</p>

          <p><strong>What you get...</strong><br/>
          The song in mp3 format sent to your&nbsp;email<br/>
          A copy of the lyrics<br/>
          A live performance of the song <small>(In Select Cities)</small></p>

          <p><strong>We also do MULTIPLES!</strong></p>
        </div>


  


        <hr/>
        <span className={styleContent.subtitleLowercase}>Our Brand Themes</span>

        <div className="grid grid--half">

          <div>

            <p><strong>1. ABC's / 123's</strong><br/>
            all things baby...even the not-so-cute stuff</p>

            <p><strong>2. "Guilty Pleasures"</strong><br/>
            you'd never admit this...but you will today!</p>

            <p><strong>3. "I've Always Wanted To Try!"</strong><br/>
            run a marathon, ride in a hot air balloon etc. </p>

            <p><strong>4. "Ugh! That's Annoying!"</strong> <br/>
            Pet Peeves</p>
            <p><strong>5. "I Told You So!"</strong><br/>
            any situation where you were right, and they were obviously wrong</p>

            <p><strong>6. "Boo! What's The Matter?"</strong> <br/>
            Phobias</p>

          </div>
          <div>
            <p><strong>7. "Through A Child's Eyes"</strong><br/>
            everything about kids...if you couldn't say it before you can now! </p>

            <p><strong>8. "From Rodents To Robots"</strong><br/>
            animals &amp; pets from warm &amp; fuzzy and everything in between </p>

            <p><em>The guidelines for these songs would be the same as our regular themed ones. Except with these the facts along with being very exact, need to be more in story form so we can create your custom song out of it! When you go to order one choose "other" under "pick a theme" and specify which one you'd like created in your email to us.</em></p>

          </div>
      </div>


        <hr/>

        <span className={styleContent.subtitleLowercase}>Click here for examples</span>

        <hr/>

        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
      <div className="grid grid--third">
        
        <div>
          <input type="hidden" name="cmd" value="_s-xclick"/>
          <input type="hidden" name="hosted_button_id" value="F5DUUWRZQLYC4"/>

          <input type="hidden" name="on0" value="Choose Song Type"/>
          <p><strong>Choose Song Type</strong></p>
          <select name="os0" className={styleContent.form__select}  >
          <option value="Standard Song">Standard Song $100.00 CAD</option>	
            <option value="Standard Song + Additional Verse (8 bars)">Standard Song + Additional Verse (8 bars) $105.00 CAD</option>	
            <option value="Standard Song + Additional Verse (16 bars)">Standard Song + Additional Verse (16 bars) $110.00 CAD</option>	
            <option value="Standard Song + Specific Words Requested For Dedication">Standard Song + Specific Words Requested For Dedication $110.00 CAD</option>	
            <option value="Standard Song + A Personal Video Message">Standard Song + A Personal Video Message $110.00 CAD</option>	
            <option value="Standard Song + On A Custom USB + A Personal Video Message">Standard Song + On A Custom USB + A Personal Video Message $120.00 CAD</option>
          </select>

        </div>

        <div>
          <input type="hidden" name="on1" value="Pick A Theme"/>
          <p><strong>Pick A Theme</strong></p>
          <select name="os1">
          <option value="Birthday">Birthday </option>	
          <option value="Anniversary">Anniversary </option>	
          <option value="Parent-To-Be">Parent-To-Be </option>	
          <option value="Graduation">Graduation </option>	
          <option value="Memorial">Memorial </option>	
          <option value="Other">Other </option>
          </select>
        </div>


        <div>		
          <input type="hidden" name="on2" value="Pick A Genre"/>
          <p><strong>Pick A Genre</strong></p>
          <select name="os2">
          <option value="Hip-Hip">Hip-Hip </option>	
          <option value="R&B">R&amp;B </option>	
          <option value="Pop">Pop </option>	
          <option value="Rock">Rock </option>	
          <option value="Reggae">Reggae </option>	
          <option value="Other">Other </option>
          </select>
        </div>

        <div>
          <input type="hidden" name="currency_code" value="CAD"/>
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
        </div>

      </div>






  </form>

      </div>

      <div className={stylePanel.panel} id="TVFilmInstrumentals">
        <div className={stylePanel.panel__header}>
          TV &amp; Film Instrumentals
        </div>
        <div className="grid grid--half">
          <div>
            <Img fluid={data.tvfilminstrumentals.childImageSharp.fluid}/>
          </div>
          <div>
            <p>Our vibe and type of sound are turly unique! So if you are searching for the music of tomorrow today, you've come to the right place!</p>
          </div>
        </div>
      </div>

      <div className={stylePanel.panel} id="PianoLessonsFromScratch!">
        <div className={stylePanel.panel__header}>
        Piano Lessons From Scratch!
        </div>
        <div className="grid grid--half">
          <div>
            <Img fluid={data.pianolessonsfromscratch.childImageSharp.fluid}/>
          </div>
          <div>
            <p>We specialize in beginner piano &amp; rudimentary theory. If you're looking for RCM prep or just to learn for the fun of it, we're here for you!</p>
            <p><strong>Samples</strong></p>
            <p>
            <small>
              <a href="https://www.instagram.com/p/BXECtWej2sF/?taken-by=iamspecyal" target="_blank" rel="noreferrer">“Lesson” Classical, Baroque</a><br/>
              <a href="https://www.instagram.com/p/BV5ZDj5H2K6/?taken-by=iamspecyal" target="_blank" rel="noreferrer">“No Longer Slaves” Contemporary Worship</a><br/>
              <a href="https://www.instagram.com/p/BS1G2NTjU1i/?taken-by=iamspecyal" target="_blank" rel="noreferrer">“Harold Tweed” Jazz (Original Composition)</a>
            </small>
            </p>
          </div>
        </div>
      </div>

      <div className={stylePanel.panel} id="Songwriting">
        <div className={stylePanel.panel__header}>
          Songwriting
        </div>
        <div className="grid grid--half">
          <div>
            <Img fluid={data.songwriting.childImageSharp.fluid}/>
          </div>
          <div>
            <p>Whether it is love songs to beef tracks, amature to commercial. Rappers and singers we'd be glad to write for you!</p>
          </div>
        </div>
      </div>

      <div className={stylePanel.panel} id="LivePerformances">
        <div className={stylePanel.panel__header}>
          Live Performances
        </div>
        <div className="grid grid--half">
          <div>
            <Img fluid={data.live_performance.childImageSharp.fluid}/>
          </div>
          <div>
            <p>If you're looking for unique artists to entertain your guests, SPECYAL T PRODUCTIONS is the right choice! <a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com</a>
            </p>
          </div>
        </div>
      </div>

      <div className={stylePanel.panel} id="ArtistConsultant">
        <div className={stylePanel.panel__header}>
        Artist Consultant
        </div>
        <div className="grid grid--half">
          <div>
          <Img fluid={data.artist_consultant.childImageSharp.fluid}/>

          </div>
          <div>
            <p>We work with various artists from established to indie with helping them put together and release albums, to facilitating their live shows and overall musical direction. Specyal T brings her many years of knowledge and expertise as well as audio and stage experience to elevate each artist to their fullest potential.</p>
          </div>
        </div>
      </div>

    </div>
  </Layout>
)

export default Services

export const query = graphql`
query {
  avenuekode: file(relativePath: {eq: "stp-services/avenue-kode.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
  custommadethemedsongs: file(relativePath: {eq: "stp-services/custom-made-themed-songs.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
  tvfilminstrumentals: file(relativePath: {eq: "stp-services/tv-film-instrumentals.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
  pianolessonsfromscratch: file(relativePath: {eq: "stp-services/piano-lessons-from-scratch.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
  songwriting: file(relativePath: {eq: "stp-services/songwriting.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
  live_performance: file(relativePath: {eq: "stp-services/live_performance.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
 
  artist_consultant: file(relativePath: {eq: "stp-services/artist_consultant.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
 
  
}
`